.btn,
app-button {
  @extend .reset-button;
}

app-button {
  &[width=full] {
    width: 100%;
  }
}


.dd-m-btn {
  @extend .reset-button;
  text-transform: uppercase;
  border-radius: rem(2px);
  letter-spacing: rem(1px);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  transition: .2s all ease;

  @media (min-width: $tablet) {
    letter-spacing: rem(1.17px);
  }

  &.outlined {
    border: rem(1px) solid currentColor;
  }

  &.filled {
    color: $white;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  // Width

  &.auto {
    width: auto;
  }

  &.full {
    width: 100%;
  }

  // Size
  &.sm {
    height: rem(32px);
    min-width: rem(32px);
    padding: 0 rem(12px);
    font-size: rem(12px);

    @media (min-width: $tablet) {
      padding: 0 rem(27px);
      height: rem(34px);
      min-width: rem(34px);
    }
  }

  &.md {
    height: rem(40px);
    min-width: rem(40px);
    padding: 0 rem(19px);
    font-size: rem(12px);

    @media (min-width: $tablet) {
      height: rem(46px);
      min-width: rem(46px);
      padding: 0 rem(14px);
      font-size: rem(14px);
    }
  }

  &.lg {
    height: rem(46px);
    min-width: rem(46px);
    padding: 0 rem(14px);
  }

  &.xlg {
    height: rem(54px);
    min-width: rem(54px);
    padding: 0 rem(14px);
    font-size: rem(12px);
    line-height: rem(15px);

    @media (min-width: $tablet) {
      font-size: rem(14px);
    }
  }

  // Color

  &.positive-dark {
    &.text {
      color: $color-positive-dark;
    }

    &.filled {
      background-color: $color-positive-dark;

      &:hover {
        background-color: $color-positive-black;
      }
    }

    &.outlined {
      color: $color-positive-dark;
    }
  }

  &.positive-light {
    &.text {
      color: $color-positive-light;
    }

    &.filled {
      color: $color-positive-dark;
      background-color: $color-positive-light;

      &:hover {
        background-color: darken($color-positive-light, 10%);
      }
    }

    &.outlined {
      color: $color-positive-dark;
      border-color: $color-positive-light;
    }
  }

  &.energized-light {
    &.text {
      color: $color-energized-light;
    }

    &.filled {
      color: $color-positive-dark;
      background-color: $color-energized-light;

      &:hover {
        background-color: darken($color-energized-light, 10%);
      }
    }

    &.outlined {
      color: $color-positive-dark;
      border-color: $color-energized-light;
    }
  }

  &.facebook {
    &.filled {
      background-color: $facebook;

      &:hover {
        background-color: $facebook-dark;
      }
    }
  }

  &.google {
    &.filled {
      background-color: $google;

      &:hover {
        background-color: $google-dark;
      }
    }
  }
}
