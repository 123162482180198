.card {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  background-color: $white;
  color: $color-positive-dark;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;

  .favorite-icon {
    cursor: pointer;
    font-size: 22px;
    position: absolute;
    top: 26px;
    right: 26px;
    z-index: 1;

    @media (min-width: $tablet) {
      right: rem(31px);
      top: rem(20px);
    }
  }

  &.lock {
    .card-figure {
      &:after {
        content: "";
        position: absolute;
        width: rem(40px);
        height: rem(40px);
        bottom: 0;
        right: 0;
        background-color: rgba($color-positive-dark, 0.8);
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url("/assets/img/icons/restrito-paciente.svg");
        background-size: 24px;
      }

      &.professionals:after {
        background-image: url("/assets/img/icons/restrito-profissionais.svg");
        background-size: 24px;
      }
    }
  }

  &-figure {
    position: relative;
    margin: 0;

    img {
      position: relative;
      left: 50%;
      transform: translate(-50%, 0);
      width: auto;
      height: auto;
      display: block;
    }

    &--sm {
      min-height: auto;
      max-height: none;
      height: auto;
      overflow: visible;
      padding: rem(27px) rem(27px) 0 rem(27px);
      display: block;
      text-align: center;

      @media (min-width: $mobile) {
        display: flex;
        min-height: rem(154px);
        max-height: rem(154px);
      }

      @media (min-width: $tablet) {
        min-height: rem(202px);
        max-height: rem(202px);
      }

      @media (min-width: $desktop) {
        min-height: rem(169px);
        max-height: rem(169px);
      }

      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        -o-object-fit: contain;
        object-fit: contain;

        @media (min-width: $mobile) {
          width: auto;
          max-width: 100%;
        }
      }
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: rem(27px);
  }

  &-title {
    flex: 1;
    margin: 0;
    margin-bottom: rem(14px);
    font-size: rem(14px);
    line-height: rem(16.8px);
    font-weight: 400;

    @media (min-width: $tablet) {
      margin-bottom: rem(20px);
      font-size: rem(16px);
      line-height: rem(18.2px);
    }
  }
}
