@import "./_fonts/all";

* {
  box-sizing: border-box;
}

html {
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  height: 100%;
  font-family: $font-family;
  font-size: rem($font-size);
  line-height: normal;
  color: $color-positive-dark;
  overflow-x: hidden;
  overflow-anchor: none;

  &.overlay {
    overflow: hidden;
  }
}

a {
  text-decoration: none;
  transition: 0.2s all ease;
}

.main {
  flex: 1;
  margin-top: rem(132px);

  @media (min-width: $tablet) {
    margin-top: rem(150px);
  }

  @media (min-width: $desktop) {
    margin-top: rem(159px);
  }
}

[hidden],
.dd-hide {
  display: none !important;
}

.cdk-overlay-dark-backdrop {
  // background: rgba(0, 13, 76, 0.5);
  background: rgba($white, 0.8);
}

.dd-b-hide-mobile {
  display: none;

  @media (min-width: $tablet) {
    display: block;
  }

  &.inline {
    @media (min-width: $tablet) {
      display: inline;
    }
  }
}

.dd-b-hide-desk {
  @media (min-width: $tablet) {
    display: none;
  }
}

.swiper-lazy:not(.swiper-lazy-loaded) {
  opacity: 0;
  transition: 1.4s all ease;
}

.swiper-lazy.swiper-lazy-loaded {
  opacity: 1;
  transition: 1.4s all ease;
}

mark {
  cursor: pointer;
  background: $color-positive-light;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li {
  &::selection {
    background: $color-positive-light;
  }
}
