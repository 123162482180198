.cdk-overlay-pane.modalFav {
  width: rem(322px) !important;

  @media (min-width: $tablet) {
    width: rem(376px) !important;
  }

  @media (min-width: $desktop) {
    width: rem(625px) !important;
  }
}

.cdk-overlay-backdrop.modalFav {
  background-color: rgba($color-energized-light, 0.92);
}
