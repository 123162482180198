.banner {
  position: relative;
}

app-banner-institutional {
  display: block;

  &:nth-child(even) {
    .banner-wrapper {
      @media (min-width: $desktop) {
        flex-direction: row-reverse;
      }
    }

    .banner-figure {
      @media (min-width: $desktop) {
        margin-left: rem(37px);
      }
    }
  }

  &:nth-child(odd) {
    .banner-figure {
      @media (min-width: $desktop) {
        margin-right: rem(37px);
      }
    }
  }
}
