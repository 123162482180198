.post-info {
  &:first-child {
    .post-info__button {
      display: none;
    }
  }

  .dd-m-post-content {
    font-size: rem(14px);
    line-height: rem(16px);
    font-weight: 100;
    margin: 0;

    strong {
      font-weight: 500;
    }

    a {
      color: $color-positive-dark;
      text-decoration: underline;

      &:hover {
        color: $color-positive-black;
        text-decoration: none;
      }

      &:visited {
        color: rgba($color-positive-dark, .6);
      }
    }

    p {
      &:not(:last-child) {
        margin-bottom: rem(16px);

        @media (min-width: $tablet) {
          margin-bottom: rem(18px);
        }
      }
    }

    h2 {
      margin: 0;
      margin-bottom: rem(14px);
      font-size: rem(14px);
      font-weight: 500;
      text-transform: uppercase;
    }

    h3 {
      margin: 0;
      margin-left: rem(27px);
      margin-bottom: rem(27px);
      text-transform: uppercase;
      font-weight: 500;
      font-size: rem(18px);
      line-height: rem(20px);

      @media (min-width: $tablet) {
        margin-left: rem(55px);
        font-size: rem(22px);
        line-height: rem(24px);
      }

      @media (min-width: $desktop) {
        margin-left: rem(37px);
      }
    }

    h4 {
      margin: 0;
      margin-bottom: rem(27px);
      text-transform: uppercase;
      font-weight: 500;
      font-size: rem(18px);
      line-height: rem(20px);

      @media (min-width: $tablet) {
        font-size: rem(20px);
        line-height: rem(22px);
      }
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }

    blockquote {
      margin: rem(27px) 0;
      padding: 0;
      padding-left: rem(14px);
      border-left: rem(2px) solid $color-positive-dark;

      @media (min-width: $tablet) {
        margin: rem(55px) 0;
        padding-left: rem(27px);
      }

      @media (min-width: $desktop) {
        margin: rem(37px) 0;
        padding-left: rem(18px);
      }

      p {
        line-height: 1.5;
      }

      cite {
        display: block;
        margin-top: rem(14px);

        @media (min-width: $tablet) {
          margin-top: rem(27px);
        }

        @media (min-width: $desktop) {
          margin-top: rem(18px);
        }
      }
    }

    &.more {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }

  &__button {
    display: inline-block;
    margin-top: 20px;
  }
}
