.slider-timeline {
  .swiper-wrapper {
    padding-left: rem(27px);

    @media (min-width: $tablet) {
      padding-left: rem(55px);
    }

    @media (min-width: $desktop) {
      padding-left: rem(37px);
    }

    @media (min-width: $desktop-hd) {
      padding-left: 0;
    }
  }

  .swiper-slide:first-child {
    .card-title,
    .card-description {
      padding-left: rem(27px);
    }

    .dot {
      transform: translateX(27px);
    }
  }
}
