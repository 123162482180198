.cdk-overlay-pane.login {
  width: rem(322px) !important;

  @media (min-width: $tablet) {
    width: rem(376px) !important;
  }
}

.cdk-overlay-pane.has-max-height .mat-dialog-content {
  max-height: 100%;
}

.cdk-overlay-backdrop.login {
  background-color: rgba(#0a2a52, 0.92);
}
