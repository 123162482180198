.list-stores {
  width: 100%;

  .list {
    margin-top: 0 !important;
  }

  ul {
    margin: rem(-7px);

    @media (min-width: $desktop) {
      margin: rem(-12px);
    }
  }

  li {
    width: 100%;
    margin: rem(13px) rem(7px);

    @media (min-width: 320px) {
      width: calc(50% - 14px);
    }

    @media (min-width: $tablet) {
      width: calc(25% - 14px);
      margin: rem(7px);
    }

    @media (min-width: $desktop) {
      width: calc(20% - 26px);
      margin: rem(13px);
    }

    > * {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}
