.mat-dialog-container {
  border-radius: 0 !important;
  padding: 0 !important;
  overflow-x: hidden !important;
}

.cdk-overlay-backdrop {
  &.onboarding {
    background-color: rgba($color-stable-light, 0.8);
    backdrop-filter: blur(5px);
    transition: 0.2s all ease;
  }
}

.cdk-overlay-pane {
  width: 100% !important;
  max-width: calc(100% - 54px) !important;

  @media (min-width: $tablet) {
    max-width: calc(100% - 110px) !important;
  }

  @media (min-width: $desktop) {
    max-width: calc(100% - 220px) !important;
  }

  &.onboarding {
    @media (min-width: $desktop) {
      max-width: rem(876px) !important;
    }

    .mat-dialog-container {
      box-shadow: none !important;
    }
  }
}

.dd-m-modal {
  position: relative;
  display: flex;
  flex-direction: column;
  color: $color-positive-dark;
  max-height: 85vh;
  overflow-y: auto;
  overflow-x: hidden;

  &--has-max {
    @media screen and (min-width: 802px) {
      max-width: rem(802px) !important;
    }
  }

  &--bg {
    height: calc(100vh - 27px);

    @media (min-width: $tablet) {
      height: calc(100vh - 55px);
    }

    @media (min-width: $desktop) {
      height: calc(100vh - 74px);
    }

    .mat-dialog-container {
      background-color: $color-stable-light;
    }
  }

  &-title {
    margin: 0;
    margin-bottom: rem(20px);
    font-size: rem(16px);
    line-height: rem(18.2px);
    font-weight: 500;

    @media (min-width: $tablet) {
      margin-bottom: rem(27px);
      font-size: rem(20px);
    }
  }

  &-subtitle {
    margin: 0;
    margin-bottom: rem(20px);
    font-size: rem(16px);
    line-height: rem(18.2px);
    font-weight: 400;

    @media (min-width: $tablet) {
      margin-bottom: rem(27px);
      font-size: rem(20px);
    }
  }

  &-nav {
    &__item {
      @extend .reset-button;
      color: $color-positive-dark;

      &.active {
        font-weight: 500;
        text-decoration: underline;
      }

      &:not(:last-child) {
        margin-right: rem(3px);

        &:after {
          content: "|";
          display: inline-block;
          margin-left: rem(3px);
        }
      }
    }
  }

  &__close {
    @extend .reset-button;
    width: rem(46px);
    height: rem(46px);
    flex: 0 0 rem(46px);
    margin-left: auto;
    margin-bottom: rem(8px);
    background-color: $color-positive-dark;
    color: $white;

    @media (min-width: $desktop) {
      margin-bottom: rem(27px);
    }
  }

  &-content {
    margin: 0 rem(27px) rem(27px);

    @media (min-width: $tablet) {
      margin: 0 rem(55px) rem(55px);
    }

    @media (min-width: $desktop) {
      margin: 0 rem(37px) rem(37px);
    }
  }

  &-nav {
    position: relative;
    height: rem(40px);

    @media (min-width: $tablet) {
      height: rem(46px);
    }
  }

  .dd-m-form {
    margin-bottom: rem(27px);
  }

  .dd-m-form-control:not(:last-child) {
    margin-bottom: 0;
  }

  .dd-m-form-control__wrap {
    margin-bottom: rem(10px);
  }

  .dd-m-slider-banner-prev,
  .dd-m-slider-banner-next {
    background-color: $color-positive-dark !important;
  }

  &-gallery {
    height: calc(100vh - 27px);

    @media (min-width: $tablet) {
      height: calc(100vh - 55px);
    }

    @media (min-width: $desktop) {
      height: calc(100vh - 74px);
    }

    .dd-m-modal-content {
      margin: 0;
      flex: 1;
    }

    .swiper-slide {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
    }

    img {
      width: 100%;

      @media (min-width: $desktop) {
        width: auto;
      }
    }
  }

  &--full.cdk-overlay-pane {
    max-width: 100% !important;
  }
}

.full-screen-modal {
  @media screen and (max-width: $tablet - 1) {
    max-width: unset !important;
    width: 100%;
    height: 100%;
  }

  &.has-max {
    max-width: 864px !important;
  }

  .mat-dialog-container {
    max-width: 100vw;
    max-height: 100vh;
    height: 100%;
    width: 100%;

    .mat-dialog-content {
      max-height: unset !important;
    }
  }
}
