@import "./info";

.dd-m-post {
  // margin-top: rem(27px);
  margin-bottom: rem(27px);

  @media (min-width: $tablet) {
    // margin-top: rem(55px);
    margin-bottom: rem(55px);
  }

  @media (min-width: $desktop) {
    // margin-top: rem(37px);
    margin-bottom: rem(37px);
  }

  &-header {
    h1 {
      margin: 0;
      margin-bottom: rem(16px);
      font-size: rem(14px);
      font-weight: 500;
      line-height: rem(16px);
      text-transform: uppercase;
      letter-spacing: rem(1px);

      @media (min-width: $tablet) {
        margin-bottom: rem(19px);
        font-size: rem(16px);
        line-height: rem(18px);
        letter-spacing: rem(1.4px);
      }
    }
  }

  &-content {
    font-size: rem(14px);
    line-height: rem(16px);
    font-weight: 300;
    word-break: break-word;

    @media (min-width: $tablet) {
      padding-right: rem(2px);
    }

    @media (min-width: $desktop) {
      padding-right: 0;
    }

    > div {
      > h2 {
        margin: 0 !important;
        margin-bottom: rem(14px) !important;
        font-size: rem(14px) !important;
        font-weight: 500 !important;
        text-transform: uppercase !important;
      }

      > h3 {
        margin: 0 !important;
        margin-left: rem(27px) !important;
        margin-bottom: rem(27px) !important;
        text-transform: uppercase !important;
        font-weight: 500 !important;
        font-size: rem(18px) !important;
        line-height: rem(20px) !important;

        @media (min-width: $tablet) {
          margin-left: rem(55px) !important;
          font-size: rem(22px) !important;
          line-height: rem(24px) !important;
        }

        @media (min-width: $desktop) {
          margin-left: rem(37px) !important;
        }
      }

      > h4 {
        margin: 0 !important;
        margin-bottom: rem(27px) !important;
        text-transform: uppercase !important;
        font-weight: 500 !important;
        font-size: rem(18px) !important;
        line-height: rem(20px) !important;

        @media (min-width: $tablet) {
          font-size: rem(20px) !important;
          line-height: rem(22px) !important;
        }
      }

      > p {
        margin: 0 !important;
        font-size: rem(14px) !important;
        line-height: rem(16px) !important;

        &:not(:last-child) {
          margin-bottom: rem(16px) !important;
        }

        a {
          color: $color-positive-dark !important;
          text-decoration: underline;

          &:hover {
            color: $color-positive-black;
            text-decoration: none;
          }

          &:visited {
            color: rgba($color-positive-dark, 0.6);
          }
        }
      }

      img {
        display: block;
        width: 100%;
        height: auto;
      }

      > blockquote {
        margin: rem(27px) 0;
        padding: 0;
        padding-left: rem(14px);
        border-left: rem(2px) solid $color-positive-dark;

        @media (min-width: $tablet) {
          margin: rem(55px) 0;
          padding-left: rem(27px);
        }

        @media (min-width: $desktop) {
          margin: rem(37px) 0;
          padding-left: rem(18px);
        }

        p {
          line-height: 1.5;
        }

        cite {
          display: block;
          margin-top: rem(14px);

          @media (min-width: $tablet) {
            margin-top: rem(27px);
          }

          @media (min-width: $desktop) {
            margin-top: rem(18px);
          }
        }
      }
    }

    iframe {
      width: 100%;
      height: rem(227px);
      margin-top: rem(10px);

      @media (min-width: $tablet) {
        height: rem(330px);
      }
    }

    .slider-list {
      max-width: 100% !important;
      margin-bottom: 0;
    }

    .slider-header {
      max-width: 100% !important;
    }

    .slider-header__title {
      margin: 0 !important;
    }

    .table-scroll {
      overflow-y: hidden;
      overflow-x: auto;
      margin: rem(20px) 0;
    }

    table {
      border: 0 solid transparent;
      border-collapse: collapse;
      border-spacing: 0;
      table-layout: fixed;
      width: 100%;
      min-width: rem(950px);
      text-align: left;
      border-top: 1px solid $color-positive-dark;
      border-bottom: 1px solid $color-positive-dark;

      @media (min-width: $tablet) {
        min-width: 100%;
      }

      tr {
        border-bottom: 1px solid $color-positive-dark;
      }

      td,
      th {
        padding: rem(16px) 0;
        vertical-align: initial;
      }

      tr:first-child td {
        font-weight: 400;
      }

      td {
        font-weight: 300;
        padding-right: rem(27px);
        font-size: rem(16px);
        line-height: rem(18px);
      }

      p {
        font-size: rem(16px);
        line-height: rem(18px);
      }

      a {
        color: inherit;
        text-decoration: underline;
      }
    }

    dt {
      font-weight: 600;
    }

    dd {
      margin: 0 0 rem(16px);
    }

    ul,
    ol {
      margin: 0;
      padding: 0;
      list-style: none;
      line-height: 1.5;

      ul,
      ol {
        margin-left: rem(14px);

        ul,
        ol {
          margin-left: rem(14px);
        }
      }
    }

    &.more {
      p {
        display: none;

        &:first-child {
          display: block;

          &:after {
            content: "[…]";
            font-weight: 500;
          }
        }
      }
    }

    .video {
      position: relative;
      overflow: hidden;
      width: 100%;
      padding-top: 56.25%;
      /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  &-figure {
    margin: rem(27px) auto;

    @media (min-width: $tablet) {
      margin: rem(24px) auto rem(30px);
    }

    @media (min-width: $desktop) {
      margin: rem(37px) auto;
    }

    &:last-child {
      margin-bottom: 0;
    }

    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }
}
