.ng-dropdown-panel .ng-dropdown-panel-items {
  max-height: rem(140px) !important;
}

.ng-select-container {
  @extend .reset-input;
  position: relative;
  width: 100%;
  border: rem(1px) solid !important;
  border-color: currentColor !important;
  font-size: rem(16px);
  color: currentColor !important;
  border-radius: rem(2px) !important;
  background-color: transparent !important;
  background-image: url("/assets/img/icons/select-arrow.svg") !important;
  background-repeat: no-repeat !important;
  background-position: center right 16px !important;
  box-shadow: none !important;

  .ng-placeholder {
    position: absolute !important;
    left: rem(16px) !important;
    display: block !important;
    color: currentColor !important;
    top: 50% !important;
    padding: 0 !important;
    transform: translateY(-50%);
    transition: 0.2s all ease;
  }

  .ng-input {
    top: 0 !important;
    height: 100%;
    padding-left: 0 !important;

    input {
      @extend .reset-input;
      height: 100%;
    }
  }
}

.ng-select {
  .ng-clear-wrapper {
    display: none !important;
  }
}

.ng-value-container {
  padding-left: 0 !important;
}

.ng-option {
  padding-left: rem(16px) !important;
  padding-right: rem(16px) !important;
  font-size: rem(16px);
  color: $color-positive-dark;
  font-weight: 300;
}

.ng-dropdown-panel {
  border-color: $color-positive-dark !important;
}

.ng-arrow-wrapper {
  display: none;
}

.ng-option-label {
  color: $color-positive-dark !important;
  font-weight: 300 !important;
}

.ng-option-marked {
  &:not(.ng-option-selected) {
    background-color: $white !important;
  }

  &.ng-option-selected {
    color: $color-positive-dark !important;
  }
}

.ng-select.ng-select-opened > .ng-select-container {
  background-color: transparent !important;
}
