.reset-button {
  -webkit-appearance: none !important;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  text-decoration: none;
  text-align: center;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;

  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
}

.reset-input {
  -webkit-appearance: none;
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  background-clip: padding-box;
  outline: 0;
  -webkit-tap-highlight-color: transparent;

  &:disabled,
  &.disabled {
    cursor: default;
  }
}

.reset-list {
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
  color: inherit;
  -webkit-appearance: none;
  background-color: transparent;
  font: inherit;
  vertical-align: baseline;
  list-style-type: none;

  a {
    color: inherit;
    text-decoration: none;
  }

  li {
    list-style-type: none;
  }
}
