@import "~src/scss/base/_variables/all";

.dd-l-wrapper {
  max-width: $desktop-hd;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  &-inner {
    @extend .dd-l-wrapper;
    max-width: calc(100% - 54px);

    @media screen and (min-width: $tablet) {
      max-width: calc(100% - 109px);
    }

    @media screen and (min-width: $desktop) {
      max-width: calc(100% - 74px);
    }

    @media screen and (min-width: $desktop-hd) {
      max-width: $desktop-hd - 74px;
    }
  }
}

.dd-l-full {
  position: relative;
  width: 100%;
  max-width: $desktop-full;
  margin-left: auto;
  margin-right: auto;
}

.dd-l-page-calculator {
  app-header,
  app-footer {
    display: none;
  }

  main.main {
    margin-top: 0;
  }
}
