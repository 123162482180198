@import "./cards";
@import "./products";
@import "./stores";

.list {
  &.margin {
    @media (min-width: $tablet) {
      margin-top: rem(35px);
    }
  }

  &-header {
    margin-bottom: rem(20px);
    position: relative;
    z-index: 2;

    @media (min-width: $tablet) {
      margin-bottom: rem(26px);
    }

    &__title {
      margin-top: 0;
      margin-bottom: 0;
      margin-left: rem(27px);
      font-size: rem(14px);
      line-height: rem(17px);
      letter-spacing: rem(0.7px);
      text-transform: uppercase;
      font-weight: 600;

      @media (min-width: $tablet) {
        margin-left: 0;
        font-size: rem(16px);
        line-height: rem(18px);
      }

      @media (min-width: $desktop) {
        margin-left: rem(27px);
      }

      strong {
        font-weight: 600;
      }

      &--md {
        @extend .list-header__title;
        font-size: rem(12px);
        line-height: rem(15px);
        margin-top: rem(27px);

        @media (min-width: $tablet) {
          font-size: rem(14px);
          line-height: rem(16px);
        }
      }
    }
  }

  &-content {
    &.is-loading {
      position: relative;

      app-spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        display: flex;
        transform: translate(-50%);
      }

      ul {
        opacity: 0.2;
      }
    }

    app-spinner {
      display: none;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      padding: 0;
    }
  }

  &-empty-message {
    @extend .list-header__title;
    margin: 40px auto;
    font-size: rem(12px);
    line-height: rem(15px);
    text-align: center;

    @media (min-width: $desktop) {
      margin-left: 0;
      margin: 40px auto;
      font-size: rem(14px);
      line-height: rem(16px);
    }
  }
}
