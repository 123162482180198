@import "./search";
@import "./block";
@import "./help";
@import "./ngselect";
@import "./generic-error";

.dd-m-form {
  .swiper-container,
  .swiper-slide {
    overflow: initial !important;
  }

  .swiper-slide {
    transition: 0.2s opacity ease;

    &:not(.swiper-slide-active) {
      opacity: 0;
    }
  }

  &-title {
    margin: 0;
    font-weight: 500;
    font-size: rem(16px);
    line-height: rem(18px);

    @media (min-width: $tablet) {
      font-size: rem(20px);
      line-height: rem(24px);
    }
  }

  &-row {
    &:not(:last-child) {
      margin-bottom: rem(20px);

      @media (min-width: $tablet) {
        margin-bottom: rem(27px);
      }
    }
  }

  &-control {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    color: $color-positive-dark;

    &:not(:last-child) {
      margin-bottom: rem(20px);
    }

    &__wrap {
      display: flex;
      justify-content: space-between;
      position: relative;
    }

    p {
      display: none;
    }

    strong {
      display: block;
      margin-bottom: rem(14px);
      font-weight: 400;
      font-size: rem(14px);
      line-height: rem(16px);

      @media (min-width: $tablet) {
        margin-bottom: rem(20px);
        font-size: rem(14px);
        line-height: rem(16px);
      }
    }

    &.error {
      color: $color-assertive !important;

      p {
        display: block;
        margin: 0;
        margin-bottom: rem(20px);
        padding: rem(6px) rem(16px);
        font-size: rem(10px);
        line-height: 16px;
        background-color: $color-assertive;
        color: $white;
        text-transform: uppercase;

        @media (min-width: $tablet) {
          padding: rem(16px);
          font-size: rem(14px);
        }
      }

      input,
      textarea,
      app-input-touch-spin label,
      app-input-touch-spin button,
      app-select-multiple .ng-placeholder,
      app-select-multiple label,
      app-select-button .ng-dropdown-panel,
      app-select-button .ng-option-label,
      app-select-button .ng-value-label,
      app-select-multiple .ng-value-label,
      app-select-multiple .ng-dropdown-panel,
      .input-touch {
        color: $color-assertive !important;
        border-color: $color-assertive !important;
      }

      app-select-button .ng-select-container:before,
      app-select-multiple .ng-select-container:before,
      app-input-touch-spin button {
        background: rgba(255, 0, 0, 0.1);
      }
    }

    &__label {
      display: block;
      width: 100%;
      font-size: rem(14px);
      font-weight: 300;
      line-height: rem(16px);

      @media (min-width: $tablet) {
        font-size: rem(16px);
      }
    }

    &__select {
      input {
        padding-left: rem(16px) !important;
      }

      &.ng-select-filtered .ng-placeholder {
        display: none !important;
      }

      &.ng-select-multiple {
        .ng-value-container .ng-star-inserted {
          display: none !important;
        }

        .ng-clear-wrapper {
          display: none !important;
        }
      }

      &:not(.ng-select-multiple) {
        .ng-select-opened,
        .ng-select-container.ng-has-value {
          .ng-placeholder {
            top: rem(16px) !important;
            font-size: rem(14px);
            text-transform: uppercase;
            font-weight: 300;
          }

          .ng-input,
          .ng-value {
            display: block !important;
            padding: 10px 0 0 !important;
          }

          .ng-value {
            padding-left: rem(16px) !important;
          }

          .ng-value-icon {
            display: none !important;
          }
        }
      }

      &.sm {
        .ng-select-container {
          height: rem(40px);

          @media (min-width: $tablet) {
            height: rem(46px);
          }
        }
      }

      &.lg {
        .ng-select-container {
          height: rem(54px);
        }
      }

      &.error {
        border-color: $color-assertive;
      }
    }

    &__radio {
      @extend .reset-input;
      width: rem(23px);
      height: rem(23px);
      border-radius: 50%;
      box-shadow: 0 0 0 rem(1px) $color-positive-dark;

      &:checked {
        background-color: $color-energized-light;
      }
    }

    &__checkbox {
      @extend .reset-input;
      width: rem(23px);
      height: rem(23px);
      border-radius: rem(2px);
      box-shadow: inset 0 0 0 rem(1px) $color-positive-dark;

      &:checked {
        background-color: $color-energized-light;
      }
    }
  }

  app-radio {
    display: block;

    &:not(:last-child) {
      margin-bottom: rem(12px);
    }
  }
}

app-input,
app-select,
app-select-gender,
app-select-blood-type,
app-select-state,
app-select-city,
app-select-pregnant-weeks {
  position: relative;
  display: block;
  width: 100%;
}
