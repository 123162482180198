.slider-mosaic {
  .slick-slider {
    margin-bottom: rem(27px);

    @media (min-width: $tablet) {
      margin-bottom: rem(55px);
    }

    @media (min-width: $desktop) {
      margin-bottom: rem(37px);
    }
  }

  .slick-list {
    padding: 0 rem(27px);

    @media (min-width: $tablet) {
      padding: 0 rem(55px);
    }

    @media (min-width: $desktop) {
      padding: 0 rem(37px);
    }

    @media (min-width: $desktop-hd) {
      padding: 0;
    }
  }

  .slick-slide {
    a {
      display: block;
      width: 100%;
      height: 100%;
    }

    app-card-mosaic-instagram {
      display: block !important;
    }

    &:not(:last-child) {
      margin-right: rem(27px);
    }

    > div:first-child {
      height: 100%;
      margin-bottom: rem(27px);
    }
  }

  .swiper-container {
    @extend .dd-l-wrapper-inner;
  }

  .carousel-mobile {
    @media (min-width: $desktop) {
      display: none;
    }

    .slider-mosaic-nav {
      display: flex;

      @media (min-width: $desktop) {
        display: none;
      }
    }

    .slick-slide {


      &:nth-child(1n+2) {
        > div {
          &:first-child {
            app-card-mosaic-instagram {
              width: rem(108px) !important;
              height: rem(108px) !important;

              @media (min-width: $tablet) {
                width: rem(165px) !important;
                height: rem(165px) !important;
              }
            }
          }

          &:last-child {
            app-card-mosaic-instagram {
              width: rem(216px) !important;
              height: rem(216px) !important;

              @media (min-width: $tablet) {
                width: rem(269px) !important;
                height: rem(269px) !important;
              }
            }
          }
        }

        &:nth-child(odd) {
          >div:first-child {
            app-card-mosaic-instagram {
              margin-left: auto;
            }
          }
        }
      }

      &:nth-child(3n+1) {
        > div {
          &:first-child {
            app-card-mosaic-instagram {
              width: rem(216px) !important;
              height: rem(216px) !important;

              @media (min-width: $tablet) {
                width: rem(269px) !important;
                height: rem(269px) !important;
              }
            }
          }

          &:last-child {
            app-card-mosaic-instagram {
              width: rem(108px) !important;
              height: rem(108px) !important;

              @media (min-width: $tablet) {
                width: rem(165px) !important;
                height: rem(165px) !important;
              }
            }
          }
        }
      }
    }
  }

  .carousel-desktop {
    display: none;

    @media (min-width: $desktop) {
      display: block;
    }

    @media (min-width: $desktop-hd) {
      max-width: rem(1366px);
      margin-left: auto;
      margin-right: auto;
    }

    .slider-mosaic-nav {
      display: none;

      @media (min-width: $desktop) {
        display: flex;
      }
    }

    app-card-mosaic-instagram {
      width: rem(218px) !important;
      height: rem(218px) !important;

      @media (min-width: $desktop-hd) {
        width: rem(322px) !important;
        height: rem(322px) !important;
      }
    }
  }
}
