.dd-m-list-cards {
  padding: rem(27px) 0;

  @media (min-width: $tablet) {
    padding: rem(55px) 0;
  }

  @media (min-width: $desktop) {
    padding: rem(37px) 0;
  }

  @media (min-width: $desktop-hd) {
    padding: rem(52px) 0;
  }

  &-container {
    display: flex;
    flex-wrap: wrap;
  }

  &.stable {
    position: relative;

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 0;
      z-index: -1;
      display: block;
      width: rem(1920px);
      height: 100%;
      background-color: $color-stable-light;
    }

    &:before {
      left: 50%;
    }

    &:after {
      right: 50%;
    }
  }

  .card {
    height: inherit;

    &-content {
      padding: 14px;

      @media (min-width: $tablet) {
        padding: 27px;
      }

      @media (min-width: $desktop) {
        padding: 54px;
      }
    }

    &-content__columns {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 14px;

      @media (min-width: $tablet) {
        grid-gap: 27px;
      }

      p {
        margin: 0;

        @media (min-width: $desktop) {
          font-size: 16px;
          line-height: 18px;
        }
      }

      strong {
        display: block;
        font-weight: 500;
        min-height: 32px;
        margin-bottom: 6px;

        @media (min-width: $desktop) {
          font-size: 16px;
          min-height: unset;
          margin-bottom: 14px;
        }
      }
    }

    &-title {
      flex: unset;
    }

    &:nth-child(1),
    &:nth-child(2) {
      width: calc(50% - 7px);

      @media (min-width: $tablet) {
        width: calc(50% - 13.5px);
      }
    }

    &:nth-child(2) {
      margin-left: 14px;

      @media (min-width: $tablet) {
        margin-left: 27px;
      }
    }

    &:nth-child(3) {
      margin-top: 14px;

      @media (min-width: $tablet) {
        margin-top: 20px;
      }

      @media (min-width: $desktop) {
        margin-top: 27px;
      }
    }
  }
}
