.list-products {
  width: 100%;

  ul {
    margin: rem(-7px);
    margin-top: rem(-18px);

    @media (min-width: $tablet) {
      margin: rem(-13px);
      margin-top: rem(-18px);
    }

    @media (min-width: $desktop) {
      margin-top: rem(-18px);
    }
  }

  li {
    width: 100%;
    margin: rem(13px) rem(7px);

    @media (min-width: 320px) {
      width: calc(50% - 14px);
    }

    @media (min-width: $tablet) {
      width: calc(50% - 26px);
      margin: rem(13px);
    }

    @media (min-width: $desktop) {
      width: calc(33.333% - 26px);
      margin: rem(13px);
    }

    >* {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}
