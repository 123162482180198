.slider-gallery {
  .swiper-wrapper {
    align-items: center;
    justify-content: center;
  }

  .slider-header {
    margin-bottom: rem(-7px);

    @media (min-width: $tablet) {
      margin-bottom: -9px;
    }

    @media (min-width: $desktop) {
      margin-bottom: -6px;
    }

    &__title {
      font-weight: 300;
      line-height: rem(16px);
    }
  }

  .slider-info {
    p {
      font-size: rem(16px);
      line-height: rem(18px);

      @media (min-width: $desktop) {
        margin-bottom: rem(19px);
      }

      &> :first-child {
        margin-top: 0;
      }
    }
  }
}
