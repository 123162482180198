@keyframes slide {
  100% {
    transform: translateX(0);
  }
}

.globalnav {
  // position: fixed !important;
  transform: translateX(-100%);
  animation: slide 0.1s forwards;
  animation-delay: 0.1s;
  width: 100%;
  max-width: 100% !important;
  height: 100%;
  background-color: #92ff00;

  @media (min-width: $desktop) {
    width: 50%;
    max-width: rem(376px) !important;
  }

  mat-dialog-container {
    background-color: unset;
  }

  mat-dialog-content {
    flex: 1;
    max-height: 100%;
  }

  .mat-dialog-container {
    padding: 0;
    overflow-x: hidden;
    border-radius: 0;
  }

  app-globalnav {
    display: block;
    height: 100%;
  }
}
