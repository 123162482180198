@import "~src/scss/layouts/all";

.slider-cards {
  .swiper-container {
    //padding: 0 rem(27px);
    @extend .dd-l-wrapper-inner;

    @media (min-width: $tablet) {
      //padding: 0 rem(55px);
    }

    @media (min-width: $desktop) {
      //padding: 0 rem(37px);
    }

    @media (min-width: $desktop-hd) {
      //padding: 0 rem(104px);
    }
  }
}

.dd-m-slider-cards {
  padding: rem(27px) 0;

  @media (min-width: $tablet) {
    padding: rem(55px) 0;
  }

  @media (min-width: $desktop) {
    padding: rem(37px) 0;
  }

  @media (min-width: $desktop-hd) {
    padding: rem(52px) 0;
  }

  &-nav {
    display: flex;
    justify-content: center;
  }

  &-prev,
  &-next {
    position: relative !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    width: rem(78px) !important;
    height: rem(21px) !important;
    padding: 0 rem(14px) !important;
    background-color: $color-positive-dark !important;
    color: $white !important;
    display: block !important;
    font-size: rem(8px) !important;
    margin: 0 rem(2px) !important;
    transition: .2s all ease !important;
    margin-top: rem(27px) !important;

    @media (min-width: $tablet) {
      width: rem(186px) !important;
      height: rem(26px) !important;
      margin-top: rem(55px) !important;
    }

    @media (min-width: $desktop) {
      margin-top: rem(37px) !important;
    }

    @media (min-width: $desktop-hd) {
      margin-top: rem(52px) !important;
    }

    &:hover {
      background-color: $color-positive-black !important;
    }

    &:after {
      font-size: rem(8px) !important;
      line-height: rem(21px) !important;

      @media (min-width: $tablet) {
        line-height: rem(26px) !important;
      }
    }
  }

  &-prev {
    text-align: left;
  }

  &-next {
    text-align: right;
  }

  &.stable {
    position: relative;
    background-color: $color-stable-light;

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 0;
      display: block;
      width: rem(1920px);
      height: 100%;
      background-color: $color-stable-light;
    }

    &:before {
      left: 50%;
    }

    &:after {
      right: 50%;
    }
  }
}
