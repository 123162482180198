$mobile: 376px;
$phablet: 414px;
$tablet: 768px;
$tablet-pro: 834px;
$tablet-lg: 970px;
$desktop: 1024px;
$desktop-sm: 1200px;
$desktop-hd: 1440px;
$desktop-hd-2: 1536px;
$desktop-full: 1920px;
