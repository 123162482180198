.form-help {
  .form-title {
    margin: 0;
    margin-bottom: rem(10px);
    font-weight: 500;
    letter-spacing: rem(.7px);

    @media (min-width: $tablet) {
      margin-bottom: rem(17px);
      font-size: rem(19px);
    }
  }
}
