@font-face {
  font-family: "icomoon";
  src: url("assets/fonts/icomoon/icomoon.eot?jtfjl3");
  src: url("assets/fonts/icomoon/icomoon.eot?jtfjl3#iefix") format("embedded-opentype"),
    url("assets/fonts/icomoon/icomoon.ttf?jtfjl3") format("truetype"),
    url("assets/fonts/icomoon/icomoon.woff?jtfjl3") format("woff"),
    url("assets/fonts/icomoon/icomoon.svg?jtfjl3#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.dd-icon,
[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-clap-full .path1:before {
  content: "\e921";
  color: rgb(145, 255, 0);
}
.icon-clap-full .path2:before {
  content: "\e922";
  margin-left: -1.0625em;
  color: rgb(0, 13, 76);
}
.icon-clap-full .path3:before {
  content: "\e923";
  margin-left: -1.0625em;
  color: rgb(0, 13, 76);
}
.icon-clap-full .path4:before {
  content: "\e924";
  margin-left: -1.0625em;
  color: rgb(0, 13, 76);
}
.icon-clap-full .path5:before {
  content: "\e925";
  margin-left: -1.0625em;
  color: rgb(0, 13, 76);
}
.icon-clap-full .path6:before {
  content: "\e926";
  margin-left: -1.0625em;
  color: rgb(0, 13, 76);
}
.icon-clap-full .path7:before {
  content: "\e927";
  margin-left: -1.0625em;
  color: rgb(0, 13, 76);
}
.icon-clap-full .path8:before {
  content: "\e928";
  margin-left: -1.0625em;
  color: rgb(0, 13, 76);
}
.icon-clap-full .path9:before {
  content: "\e929";
  margin-left: -1.0625em;
  color: rgb(0, 13, 76);
}
.icon-clap-full .path10:before {
  content: "\e92a";
  margin-left: -1.0625em;
  color: rgb(0, 13, 76);
}
.icon-clap-full .path11:before {
  content: "\e92b";
  margin-left: -1.0625em;
  color: rgb(0, 13, 76);
}
.icon-clap-full .path12:before {
  content: "\e92c";
  margin-left: -1.0625em;
  color: rgb(0, 13, 76);
}
.icon-clap-full .path13:before {
  content: "\e92d";
  margin-left: -1.0625em;
  color: rgb(0, 13, 76);
}
.icon-clap-full .path14:before {
  content: "\e92e";
  margin-left: -1.0625em;
  color: rgb(0, 13, 76);
}
.icon-clap-full .path15:before {
  content: "\e92f";
  margin-left: -1.0625em;
  color: rgb(0, 13, 76);
}
.icon-clap-full .path16:before {
  content: "\e930";
  margin-left: -1.0625em;
  color: rgb(0, 13, 76);
}
.icon-clap-full .path17:before {
  content: "\e931";
  margin-left: -1.0625em;
  color: rgb(0, 13, 76);
}
.icon-clap-full .path18:before {
  content: "\e932";
  margin-left: -1.0625em;
  color: rgb(0, 13, 76);
}
.icon-clap-full .path19:before {
  content: "\e933";
  margin-left: -1.0625em;
  color: rgb(0, 13, 76);
}
.icon-clap-full .path20:before {
  content: "\e934";
  margin-left: -1.0625em;
  color: rgb(0, 13, 76);
}
.icon-clap-full .path21:before {
  content: "\e935";
  margin-left: -1.0625em;
  color: rgb(0, 13, 76);
}
.icon-clap-full .path22:before {
  content: "\e936";
  margin-left: -1.0625em;
  color: rgb(0, 13, 76);
}
.icon-clap-full .path23:before {
  content: "\e937";
  margin-left: -1.0625em;
  color: rgb(0, 13, 76);
}
.icon-spinner:before {
  content: "\e938";
}
.icon-share:before {
  content: "\e939";
}
.icon-dots:before {
  content: "\e93a";
}
.icon-clap:before {
  content: "\e93b";
}
.icon-balloon:before {
  content: "\e93c";
}
.icon-send:before {
  content: "\e91d";
}
.icon-twitter:before {
  content: "\e91e";
}
.icon-whatsapp:before {
  content: "\e91f";
}
.icon-anchor:before {
  content: "\e920";
}
.icon-book:before {
  content: "\e91a";
}
.icon-warning:before {
  content: "\e91b";
}
.icon-hands:before {
  content: "\e91c";
}
.icon-start:before {
  content: "\e919";
}
.icon-in:before {
  content: "\e916";
}
.icon-fb:before {
  content: "\e917";
}
.icon-ig:before {
  content: "\e918";
}
.icon-heart-full .path1:before {
  content: "\e913";
  color: rgb(145, 255, 0);
}
.icon-heart-full .path2:before {
  content: "\e914";
  margin-left: -1.181640625em;
  color: rgb(0, 13, 76);
}
.icon-heart-full .path3:before {
  content: "\e915";
  margin-left: -1.181640625em;
  color: rgb(0, 13, 76);
}
.icon-minus-solid:before {
  content: "\e912";
}
.icon-camera:before {
  content: "\e911";
}
.icon-search:before {
  content: "\e910";
}
.icon-more:before {
  content: "\e90f";
}
.icon-heart:before {
  content: "\e90e";
}
.icon-expand:before {
  content: "\e903";
}
.icon-arrow-left:before {
  content: "\e900";
}
.icon-arrow-top:before {
  content: "\e901";
}
.icon-arrow-right:before {
  content: "\e902";
}
.icon-pointer-right:before {
  content: "\e904";
}
.icon-pointer-bottom:before {
  content: "\e905";
}
.icon-pointer-top:before {
  content: "\e90a";
}
.icon-pointer-left:before {
  content: "\e90d";
}
.icon-close:before {
  content: "\e907";
}
.icon-menu:before {
  content: "\e906";
}
.icon-health:before {
  content: "\e908";
}
.icon-logo:before {
  content: "\e909";
}
.icon-user:before {
  content: "\e90b";
}
.icon-woman:before {
  content: "\e90c";
}
