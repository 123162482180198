.dd-m-form-generic-error {
  display: block;
  margin: 0;
  margin-bottom: rem(20px);
  padding: rem(6px) rem(16px);
  font-size: rem(10px);
  line-height: 16px;
  background-color: $color-assertive;
  color: $white;
  text-transform: uppercase;

  @media (min-width: $tablet) {
    padding: rem(16px);
    font-size: rem(14px);
  }
}
