@import "./base";
@import "./globalnav";
@import "./login";
@import "./modalFav";

.cdk-overlay-pane.complete-registration {
  z-index: 1002 !important;
  width: rem(306px) !important;

  @media (min-width: $tablet) {
    width: rem(498px) !important;
  }

  @media (min-width: $desktop) {
    &:not(.has-max) {
      width: rem(552px) !important;
    }
  }
}

.cdk-overlay-backdrop.complete-registration {
  background-color: rgba(#fff, 0.8);
}

.bg-energized {
  background-color: rgba($color-energized-light-2, 0.8);
}

.shadow-thin .mat-dialog-container {
  box-shadow: 0 0 10px 20px rgba(0, 0, 0, 0.02);
}

.dd-m-modal-small {
  max-width: 306px !important;
}
