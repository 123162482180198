@font-face {
  font-family: "Sanomat Sans";
  src: url("/assets/fonts/sanomatsans/SanomatSans-Bold.eot");
  src: url("/assets/fonts/sanomatsans/SanomatSans-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/sanomatsans/SanomatSans-Bold.woff2") format("woff2"),
    url("/assets/fonts/sanomatsans/SanomatSans-Bold.woff") format("woff"),
    url("/assets/fonts/sanomatsans/SanomatSans-Bold.ttf") format("truetype"),
    url("/assets/fonts/sanomatsans/SanomatSans-Bold.svg#SanomatSans-Bold")
      format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Sanomat Sans";
  src: url("/assets/fonts/sanomatsans/SanomatSans-Regular.eot");
  src: url("/assets/fonts/sanomatsans/SanomatSans-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/sanomatsans/SanomatSans-Regular.woff2") format("woff2"),
    url("/assets/fonts/sanomatsans/SanomatSans-Regular.woff") format("woff"),
    url("/assets/fonts/sanomatsans/SanomatSans-Regular.ttf") format("truetype"),
    url("/assets/fonts/sanomatsans/SanomatSans-Regular.svg#SanomatSans-Regular")
      format("svg");
  font-weight: normal;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Sanomat Sans";
  src: url("/assets/fonts/sanomatsans/SanomatSans-Medium.eot");
  src: url("/assets/fonts/sanomatsans/SanomatSans-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/sanomatsans/SanomatSans-Medium.woff2") format("woff2"),
    url("/assets/fonts/sanomatsans/SanomatSans-Medium.woff") format("woff"),
    url("/assets/fonts/sanomatsans/SanomatSans-Medium.ttf") format("truetype"),
    url("/assets/fonts/sanomatsans/SanomatSans-Medium.svg#SanomatSans-Medium")
      format("svg");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Sanomat Sans";
  src: url("/assets/fonts/sanomatsans/SanomatSans-Black.eot");
  src: url("/assets/fonts/sanomatsans/SanomatSans-Black.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/sanomatsans/SanomatSans-Black.woff2") format("woff2"),
    url("/assets/fonts/sanomatsans/SanomatSans-Black.woff") format("woff"),
    url("/assets/fonts/sanomatsans/SanomatSans-Black.ttf") format("truetype"),
    url("/assets/fonts/sanomatsans/SanomatSans-Black.svg#SanomatSans-Black")
      format("svg");
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Sanomat Sans";
  src: url("/assets/fonts/sanomatsans/SanomatSans-Light.eot");
  src: url("/assets/fonts/sanomatsans/SanomatSans-Light.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/sanomatsans/SanomatSans-Light.woff2") format("woff2"),
    url("/assets/fonts/sanomatsans/SanomatSans-Light.woff") format("woff"),
    url("/assets/fonts/sanomatsans/SanomatSans-Light.ttf") format("truetype"),
    url("/assets/fonts/sanomatsans/SanomatSans-Light.svg#SanomatSans-Light")
      format("svg");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Sanomat Sans";
  src: url("/assets/fonts/sanomatsans/SanomatSans-RegularItalic.eot");
  src: url("/assets/fonts/sanomatsans/SanomatSans-RegularItalic.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/sanomatsans/SanomatSans-RegularItalic.woff2")
      format("woff2"),
    url("/assets/fonts/sanomatsans/SanomatSans-RegularItalic.woff")
      format("woff"),
    url("/assets/fonts/sanomatsans/SanomatSans-RegularItalic.ttf")
      format("truetype"),
    url("/assets/fonts/sanomatsans/SanomatSans-RegularItalic.svg#SanomatSans-RegularItalic")
      format("svg");
  font-weight: normal;
  font-display: swap;
  font-style: italic;
}
