.form-search {
  .btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: rem(4px);

    @media (min-width: $tablet) {
      right: rem(6px);
    }
  }
}
