@keyframes flickerAnimation {
  0%   { opacity: 0; }
  50%  { opacity: 1; }
  100% { opacity: 0; }
}

@-o-keyframes flickerAnimation{
  0%   { opacity: 0; }
  50%  { opacity: 1; }
  100% { opacity: 0; }
}

@-moz-keyframes flickerAnimation{
  0%   { opacity: 0; }
  50%  { opacity: 1; }
  100% { opacity: 0; }
}

@-webkit-keyframes flickerAnimation{
  0%   { opacity: 0; }
  50%  { opacity: 1; }
  100% { opacity: 0; }
}

.dd-animate-flicker {
   -webkit-animation: flickerAnimation 1s infinite;
   -moz-animation: flickerAnimation 3s infinite;
   -o-animation: flickerAnimation 3s infinite;
    animation: flickerAnimation 3s infinite;
}
